
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Great Ocean Road - Australia | Kiwi & Cacahuate" description="Weekend drive to visit see the great ocean road" url="https://kiwicacahuate.com/great-ocean-road/" image="https://kiwicacahuate.com/great-ocean-road/great-ocean-road.jpg" imageWidth="4000" imageHeight="2668" />

    <div className="">
      <div className="story">
        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="002">
                <source srcSet="/great-ocean-road/great-ocean-road.webp, /great-ocean-road/great-ocean-road.webp 2x" />
                <source srcSet="/great-ocean-road/great-ocean-road.jpg, /great-ocean-road/great-ocean-road.jpg 2x" />
                <img src="/great-ocean-road/great-ocean-road.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-story">
            <h2 className="dairy-title">Great Ocean Road - Australia</h2>
            <div className="photoset-intro left-align">
              <p></p>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten"> </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="002">
                <source srcSet="/great-ocean-road/thumbnail/002.webp, /great-ocean-road/thumbnail/002-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/002.jpg, /great-ocean-road/thumbnail/002-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/002.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="003">
                <source srcSet="/great-ocean-road/thumbnail/003.webp, /great-ocean-road/thumbnail/003-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/003.jpg, /great-ocean-road/thumbnail/003-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/003.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="004">
                <source srcSet="/great-ocean-road/thumbnail/004.webp, /great-ocean-road/thumbnail/004-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/004.jpg, /great-ocean-road/thumbnail/004-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/004.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="005">
                <source srcSet="/great-ocean-road/thumbnail/005.webp, /great-ocean-road/thumbnail/005-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/005.jpg, /great-ocean-road/thumbnail/005-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/005.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="006">
                <source srcSet="/great-ocean-road/thumbnail/006.webp, /great-ocean-road/thumbnail/006-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/006.jpg, /great-ocean-road/thumbnail/006-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/006.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="007">
                <source srcSet="/great-ocean-road/thumbnail/007.webp, /great-ocean-road/thumbnail/007-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/007.jpg, /great-ocean-road/thumbnail/007-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/007.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="008">
                <source srcSet="/great-ocean-road/thumbnail/008.webp, /great-ocean-road/thumbnail/008-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/008.jpg, /great-ocean-road/thumbnail/008-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/008.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="009">
                <source srcSet="/great-ocean-road/thumbnail/009.webp, /great-ocean-road/thumbnail/009-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/009.jpg, /great-ocean-road/thumbnail/009-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/009.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="010">
                <source srcSet="/great-ocean-road/thumbnail/010.webp, /great-ocean-road/thumbnail/010-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/010.jpg, /great-ocean-road/thumbnail/010-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/010.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="011">
                <source srcSet="/great-ocean-road/thumbnail/011.webp, /great-ocean-road/thumbnail/011-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/011.jpg, /great-ocean-road/thumbnail/011-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/011.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="013">
                <source srcSet="/great-ocean-road/thumbnail/013.webp, /great-ocean-road/thumbnail/013-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/013.jpg, /great-ocean-road/thumbnail/013-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/013.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="014">
                <source srcSet="/great-ocean-road/thumbnail/014.webp, /great-ocean-road/thumbnail/014-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/014.jpg, /great-ocean-road/thumbnail/014-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/014.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="015">
                <source srcSet="/great-ocean-road/thumbnail/015.webp, /great-ocean-road/thumbnail/015-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/015.jpg, /great-ocean-road/thumbnail/015-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/015.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="016">
                <source srcSet="/great-ocean-road/thumbnail/016.webp, /great-ocean-road/thumbnail/016-2x.webp 2x" />
                <source srcSet="/great-ocean-road/thumbnail/016.jpg, /great-ocean-road/thumbnail/016-2x.jpg 2x" />
                <img src="/great-ocean-road/thumbnail/016.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

